import {
  Typography,
  Skeleton as DSSkeleton,
  PillButton,
  Tooltip,
  Button,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  HeaderContainer,
  HeaderText,
  StyledActionContainer,
  StyledButtonContent,
  StyledDownloadIcon,
  Subtitle,
} from "./styles";
import { useExportsContext } from "src/shared/context/export.context";
import { useNavigate, useParams } from "react-router-dom";
import { useCopyReport } from "src/hooks/useCopyReport";
import { ReactNode } from "react";

type HeaderProps = {
  title: string;
  subtitle: ReactNode;
  editable: boolean;
};

const Component = ({ title, subtitle, editable }: HeaderProps) => {
  const { onOpenModal, onOpenDrawer } = useExportsContext();
  const navigate = useNavigate();
  const { copyReport } = useCopyReport();
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return null;
  }

  return (
    <HeaderContainer>
      <HeaderText>
        <Typography variant="headline6">{title}</Typography>
        {subtitle}
      </HeaderText>
      <StyledActionContainer>
        {editable && (
          <Tooltip title="Editar relatório">
            <div>
              <PillButton
                onClick={() => navigate(`/reports/${id}/customize`)}
                size="small"
                variant="default"
                type="secondary"
                icon="IconPencil"
              />
            </div>
          </Tooltip>
        )}
        <Tooltip title="Duplicar relatório">
          <div>
            <PillButton
              onClick={() => copyReport(id)}
              size="small"
              variant="default"
              type="secondary"
              icon="IconBoxMultiple"
            />
          </div>
        </Tooltip>
        <Tooltip title="Exportar relatório">
          <div>
            <PillButton
              onClick={() => onOpenModal(id, title)}
              size="small"
              variant="default"
              type="secondary"
              icon="IconDownload"
            />
          </div>
        </Tooltip>
        <Button
          onClick={() => onOpenDrawer()}
          variantType="neutral"
          variant="secondary"
          size="medium"
        >
          <Tooltip title="Sempre que exportar um relatório, você poderá consultar seu processamento e baixá-lo por aqui.">
            <StyledButtonContent>
              Central de downloads <StyledDownloadIcon name="IconDownload" />
            </StyledButtonContent>
          </Tooltip>
        </Button>
      </StyledActionContainer>
    </HeaderContainer>
  );
};

const Skeleton = () => {
  return (
    <HeaderContainer>
      <HeaderText>
        <DSSkeleton animation="pulse" height={38} width={300} />
        <DSSkeleton animation="pulse" height={20} width={120} />
      </HeaderText>
    </HeaderContainer>
  );
};

const Header = {
  Component,
  Skeleton,
};

export { Header };
