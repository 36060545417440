import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { FooterContainer } from "./styles";
import {
  Steps,
  useEditReport,
} from "src/pages/EditReportPage/context/EditReport.context";
import { useNavigate } from "react-router-dom";

const Component = () => {
  const { setCurrentStep } = useEditReport();
  const navigate = useNavigate();
  return (
    <FooterContainer>
      <LinkButton onClick={() => navigate("/reports")} variant="neutral">
        Cancelar
      </LinkButton>
      <Button
        onClick={() => setCurrentStep(Steps.FORM)}
        variant="primary"
        variantType="default"
      >
        Continuar <Icons name="IconArrowRight" />
      </Button>
    </FooterContainer>
  );
};

const Footer = {
  Component,
};

export { Footer };
