import AppRouter from "./routes";
import { ThemeProvider, ToastProvider } from "@flash-tecnologia/hros-web-ui-v2";
import { trpc, trpcClient, queryClient } from "./api/client";
import { QueryClientProvider } from "@tanstack/react-query";

import "../src/i18n";
import { ExportsProvider } from "./shared/context/export.context";
import { ReportsProvider } from "./pages/ReportsPage/context/Reports.context";

export default function Root() {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <ExportsProvider>
            <ReportsProvider>
              <ToastProvider
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                maxSnack={3}
              >
                <AppRouter />
              </ToastProvider>
            </ReportsProvider>
          </ExportsProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}
