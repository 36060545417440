import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { trpc } from "src/api/client";
import { useToast } from "./useToast";

export const useCopyReport = () => {
  const utils = trpc.useContext();
  const { showToast } = useToast();
  const { selectedCompany } = useSelectedCompany();
  const { mutateAsync, isLoading } = trpc.copyReport.useMutation({
    onSuccess: async () => {
      await utils.findReports.invalidate({ companyId: selectedCompany.id });
      showToast({
        type: "success",
        content: "Relatório duplicado com sucesso!",
        description:
          "O relatório foi duplicado com êxito! Agora, você pode editá-lo e visualizá-lo na lista de relatórios.",
      });
    },
  });

  const copyReport = async (reportId: string) => {
    const data = await mutateAsync({ companyId: selectedCompany.id, reportId });

    return data;
  };

  return { copyReport, isLoading };
};
