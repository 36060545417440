import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { FooterContainer, StyledButtonContainer } from "./styles";
import {
  Steps,
  useEditReport,
} from "src/pages/EditReportPage/context/EditReport.context";
import { trpc } from "src/api/client";
import { useParams } from "react-router-dom";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

const Component = () => {
  const { setCurrentStep, initialColumns, reportInfo, filter } =
    useEditReport();
  const { id } = useParams<{ id: string }>();
  const { selectedCompany } = useSelectedCompany();

  if (!id) {
    return null;
  }

  const { mutateAsync: updateReport, isLoading } =
    trpc.updateReport.useMutation({
      onSuccess() {
        setCurrentStep(Steps.SUCCESS);
      },
    });

  return (
    <FooterContainer>
      <LinkButton variant="neutral">Cancelar</LinkButton>
      <StyledButtonContainer>
        <Button
          onClick={() => setCurrentStep(Steps.CUSTOMIZE)}
          variant="secondary"
          variantType="default"
        >
          <Icons name="IconArrowLeft" />
          Voltar
        </Button>
        <Button
          loading={isLoading}
          disabled={isLoading}
          onClick={() =>
            updateReport({
              reportId: id,
              title: reportInfo.title,
              description: reportInfo.description,
              columns: initialColumns,
              private: reportInfo.private,
              companyId: selectedCompany.id,
              filter,
            })
          }
          variant="primary"
          variantType="default"
        >
          Salvar Relatório <Icons name="IconArrowRight" />
        </Button>
      </StyledButtonContainer>
    </FooterContainer>
  );
};

const Footer = {
  Component,
};

export { Footer };
