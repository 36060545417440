import { Checkbox, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledDateFilterContainer,
  StyledOptionContainer,
  StyledOptionsContainer,
  StyledTitle,
} from "./styles";
import { Filter } from "src/shared/types";

type EnumFilterProps = {
  title: string;
  column: string;
  options: string[];
  value: string[];
  onChange: <T extends Filter>(filterData: T) => void;
};

export const EnumFilter = ({
  title,
  options,
  onChange,
  value,
  column,
}: EnumFilterProps) => {
  return (
    <StyledDateFilterContainer>
      <StyledTitle variant="headline9" weight={700}>
        {title}
      </StyledTitle>
      <StyledOptionsContainer>
        {options.map((opt) => (
          <StyledOptionContainer
            active={value.includes(opt)}
            onClick={() => {
              onChange({
                type: "ENUM",
                column: column,
                displayName: title,
                options: value.includes(opt)
                  ? value.filter((o) => o !== opt)
                  : [...value, opt],
              });
            }}
          >
            <Checkbox checked={value.includes(opt)} />
            <Typography variant="body4">{opt}</Typography>
          </StyledOptionContainer>
        ))}
      </StyledOptionsContainer>
    </StyledDateFilterContainer>
  );
};
