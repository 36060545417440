import {
  Breadcrumbs,
  Icons,
  LinkButton,
  NotificationCard,
  PageContainer,
  Skeleton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Header } from "./components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "./components/Table";
import { trpc } from "src/api/client";
import { useEffect, useState } from "react";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import {
  StyledBreadcrumbContainer,
  StyledLinkContainer,
  StyledLinkIcon,
  StyledNotificationCardContainer,
} from "./styles";
import { DrawerFilter } from "./components/DrawerFilter";
import { useFilter } from "src/hooks/useFilter";
import { Subtitle } from "./components/Header/styles";

export const ReportPage = () => {
  const { id } = useParams<{ id: string }>();
  const { selectedCompany } = useSelectedCompany();
  const { filter, applyFilter, parseFilters } = useFilter();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const navigate = useNavigate();

  const {
    data: report,
    isLoading: isReportLoading,
    error: reportError,
  } = trpc.findReportById.useQuery({
    reportId: id || "",
    companyId: selectedCompany.id,
  });

  useEffect(() => {
    if (report?.filter) {
      const parsedFilters = parseFilters(report.filter);
      parsedFilters.forEach((f) => applyFilter(f));
    }
  }, [report?.filter]);

  const {
    data: queryResult,
    isLoading: isQueryLoading,
    error: queryError,
  } = trpc.queryReport.useQuery({
    reportId: id || "",
    page: pagination.pageNumber,
    pageSize: pagination.pageSize,
    companyId: selectedCompany.id,
    columns: report?.columns || [],
    filter: filter,
  });

  if (reportError || queryError) {
    return <p>Error</p>; // Todo - Check error page provided by Design
  }

  const breadcrumbs = [
    <LinkButton
      key="central"
      variant="neutral"
      onClick={() => navigate("/reports")}
    >
      Central de Relatórios
    </LinkButton>,
    !isReportLoading ? (
      report?.title && (
        <LinkButton key="report-title" variant="neutral">
          {report.title}
        </LinkButton>
      )
    ) : (
      <Skeleton animation="pulse" height={20} width={120} />
    ),
  ].filter(Boolean);

  return (
    <PageContainer>
      <StyledBreadcrumbContainer>
        <Breadcrumbs
          separator={<Icons name="IconChevronRight" />}
          breadcrumbs={breadcrumbs}
        />
      </StyledBreadcrumbContainer>
      {(isReportLoading || isQueryLoading) && (
        <StyledNotificationCardContainer>
          <NotificationCard.Root type="info" variant="outlined">
            <NotificationCard.WrapperIconWithText>
              <NotificationCard.Icon iconName="IconInfoCircle" size={20} />
              <NotificationCard.WrapperTexts>
                <NotificationCard.Title>
                  Relatório em carregamento
                </NotificationCard.Title>
                <NotificationCard.Subtitle>
                  Em breve o relatório estará disponível para visualização!
                </NotificationCard.Subtitle>
              </NotificationCard.WrapperTexts>
            </NotificationCard.WrapperIconWithText>
          </NotificationCard.Root>
        </StyledNotificationCardContainer>
      )}
      {!isReportLoading ? (
        <Header.Component
          editable={report.editable}
          title={report.title}
          subtitle={
            <Subtitle>
              {`Criado por ${report.owner}`}
              <StyledLinkContainer>
                {report.reference && (
                  <>
                    <Typography variant="caption">•</Typography>
                    <LinkButton
                      href={report.reference.link}
                      variant="default"
                      underlined={false}
                    >
                      {report.reference.title}
                      <StyledLinkIcon name="IconExternalLink" size={20} />
                    </LinkButton>
                  </>
                )}
              </StyledLinkContainer>
            </Subtitle>
          }
        />
      ) : (
        <Header.Skeleton />
      )}
      <Table
        headers={report?.columns.filter((col) => col.visible)}
        data={queryResult?.data}
        isLoading={isQueryLoading || isReportLoading}
        pagination={pagination}
        onPagination={setPagination}
        total={queryResult?.total as number}
        onFilter={() => setOpenDrawer(true)}
      />
      <DrawerFilter
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onChange={applyFilter}
        filter={filter}
        columns={report?.columns.filter((c) => c.visible) || []}
      />
    </PageContainer>
  );
};
