import { PageContainer, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { Header } from "./components";
import {
  StyledModuleItem,
  StyledModuleItemLabel,
  StyledModuleList,
  StyledTableContainer,
  StyledTabs,
} from "./styles";
import { useEffect, useMemo, useState } from "react";
import { trpc } from "src/api/client";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { Table } from "./components/Table";
import { useExportsContext } from "src/shared/context/export.context";

export const ReportsPage = () => {
  const { onOpenModal, onOpenDrawer } = useExportsContext();
  const [selectedUnit, setSelectedUnit] = useState<string | null>("");
  const [selectedModule, setSelectedModule] = useState<string | null>(null);

  const { selectedCompany } = useSelectedCompany();

  const { data, isLoading } = trpc.findModules.useQuery({
    companyId: selectedCompany.id,
  });

  const units = useMemo(
    () => [
      ...(data?.map((module) => ({
        title: module.unit,
        modules: module.modules,
      })) || []),
    ],
    [data]
  );

  useEffect(() => {
    const unit = units.find((u) => u.title === selectedUnit);

    if (unit) {
      setSelectedModule(unit.modules[0].id || null);
    }
  }, [selectedUnit]);

  useEffect(() => {
    if (units && units?.length > 0) {
      setSelectedUnit(units[0].title);
    }
  }, [units]);

  return (
    <>
      <PageContainer>
        <Header openDrawer={() => onOpenDrawer()} />
        {isLoading && <Skeleton width="100%" height={400} animation="wave" />}
        {units.length > 1 && (
          <StyledTabs
            id="onboarding-tabs"
            onTabChanged={(index) => setSelectedUnit(units[index].title)}
            selected={units.findIndex((unit) => unit.title === selectedUnit)}
            tabItens={units?.map((unit) => ({
              label: unit.title,
              component: (
                <StyledTableContainer>
                  <StyledModuleList>
                    {unit.modules.map((module) => (
                      <StyledModuleItem
                        onClick={() => setSelectedModule(module.id || null)}
                        active={selectedModule === module.id}
                      >
                        <StyledModuleItemLabel
                          active={selectedModule === module.id}
                          variant="body4"
                        >
                          {module.name}
                        </StyledModuleItemLabel>
                      </StyledModuleItem>
                    ))}
                  </StyledModuleList>
                  <Table
                    moduleId={selectedModule}
                    openModal={(reportId, reportTitle) => {
                      onOpenModal(reportId, reportTitle);
                    }}
                  />
                </StyledTableContainer>
              ),
            }))}
          />
        )}
      </PageContainer>
    </>
  );
};
