import { useState } from "react";

export const useDrag = (
  initialColumns: any[],
  setInitialColumns: React.Dispatch<React.SetStateAction<any[]>>
) => {
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);

  const handleDragStart = (index: number) => {
    setDraggingIndex(index);
  };

  const handleDragOver = (
    event: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    event.preventDefault();
    if (draggingIndex === null || draggingIndex === index) return;

    const newItems = [...initialColumns];
    const draggedItem = newItems.splice(draggingIndex, 1)[0];
    newItems.splice(index, 0, draggedItem);

    setDraggingIndex(index);
    setInitialColumns(newItems);
  };

  const handleDrop = () => {
    setDraggingIndex(null);
  };

  return {
    handleDragStart,
    handleDragOver,
    handleDrop,
    draggingIndex,
  };
};
