import React, { createContext, useContext, useState, ReactNode } from "react";

interface ExportContextType {
  openDrawer: boolean;
  exportModal: {
    opened: boolean;
    reportId: string;
    reportTitle: string;
  };
  onCloseModal: () => void;
  onOpenModal: (reportId: string, reportTitle: string) => void;
  onOpenDrawer: () => void;
  onCloseDrawer: () => void;
}

const ExportsContext = createContext<ExportContextType | undefined>(undefined);

interface ExportsProviderProps {
  children: ReactNode;
}

export const ExportsProvider: React.FC<ExportsProviderProps> = ({
  children,
}) => {
  const [exportModal, setExportModal] = useState({
    opened: false,
    reportId: "",
    reportTitle: "",
  });
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const onCloseModal = () => {
    setExportModal({
      opened: false,
      reportId: "",
      reportTitle: "",
    });
  };

  const onOpenModal = (reportId: string, reportTitle: string) => {
    setExportModal({
      opened: true,
      reportId,
      reportTitle,
    });
  };

  return (
    <ExportsContext.Provider
      value={{
        exportModal,
        onCloseModal,
        onOpenModal,
        openDrawer,
        onOpenDrawer: () => setOpenDrawer(true),
        onCloseDrawer: () => setOpenDrawer(false),
      }}
    >
      {children}
    </ExportsContext.Provider>
  );
};

export const useExportsContext = (): ExportContextType => {
  const context = useContext(ExportsContext);
  if (!context) {
    throw new Error("useReportsContext must be used within a ExportsProvider");
  }
  return context;
};
