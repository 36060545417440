import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { FooterContainer, StyledButtonContainer } from "./styles";
import { useNavigate } from "react-router-dom";

const Component = () => {
  const navigate = useNavigate();
  return (
    <FooterContainer>
      <StyledButtonContainer>
        <Button
          onClick={() => navigate("/reports")}
          variant="secondary"
          variantType="neutral"
        >
          Fechar
          <Icons name="IconCheck" />
        </Button>
      </StyledButtonContainer>
    </FooterContainer>
  );
};

const Footer = {
  Component,
};

export { Footer };
