import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledBreadcrumbContainer = styled.div`
  margin-top: 40px;
`;

export const StyledNotificationCardContainer = styled.div`
  padding-top: 40px;
`;

export const StyledLinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StyledLinkIcon = styled(Icons)`
  margin-left: 4px;
`;
