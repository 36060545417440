import { Modal } from "src/components/Modal";
import {
  StyledContentContainer,
  StyledDateContainer,
  StyledFooterContainer,
  StyledModalTitle,
} from "./styles";
import {
  Button,
  DatePicker,
  LinkButton,
  SelectField,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Column } from "src/shared/types";
import { useMemo, useState } from "react";
import { useEditReport } from "../../../../context/EditReport.context";
import { trpc } from "src/api/client";

type ModalFilterProps = {
  columns: Column[];
  open: boolean;
  onClose: () => void;
};

const enableTypes = ["ENUM", "DATE", "TEXT"];

export const ModalFilter = ({ columns, open, onClose }: ModalFilterProps) => {
  const { applyFilter } = useEditReport();
  const utils = trpc.useContext();
  const [selectedColumn, setSelectedColumn] = useState<Column | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [textFilter, setTextFilter] = useState<string>("");
  const [dateRange, setDateRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: null,
    endDate: null,
  });

  const closeModal = () => {
    setSelectedColumn(null);
    setSelectedOptions([]);
    setTextFilter("");
    setDateRange({
      startDate: null,
      endDate: null,
    });
    onClose();
  };

  const handleApplyFilter = async () => {
    if (!selectedColumn) return;

    if (selectedColumn.type === "ENUM") {
      applyFilter({
        type: "ENUM",
        column: selectedColumn.name,
        displayName: selectedColumn.displayName || selectedColumn.name,
        options: selectedOptions,
      });
    }

    if (selectedColumn.type === "DATE") {
      applyFilter({
        type: "DATE",
        column: selectedColumn.name,
        displayName: selectedColumn.displayName || selectedColumn.name,
        dateRange,
      });
    }

    if (selectedColumn.type === "TEXT") {
      applyFilter({
        type: "TEXT",
        column: selectedColumn.name,
        displayName: selectedColumn.displayName || selectedColumn.name,
        value: textFilter,
      });
    }

    onClose();
    await utils.queryReport.invalidate();
  };

  const disabledButton = useMemo(() => {
    if (selectedColumn === null) {
      return true;
    }
    if (selectedColumn.type === "ENUM" && selectedOptions.length === 0) {
      return true;
    }
    if (
      selectedColumn.type === "DATE" &&
      dateRange.startDate === null &&
      dateRange.endDate === null
    ) {
      return true;
    }

    return false;
  }, [selectedColumn, selectedOptions, dateRange]);

  const validColumns = useMemo(() => {
    return columns
      .filter((col) => enableTypes.includes(col.type))
      .filter((col) => col.visible);
  }, [columns]);

  return (
    <Modal isOpen={open} onClose={closeModal} size="xs">
      <Modal.Header>
        <StyledModalTitle variant="headline8">
          Adicionar filtro
        </StyledModalTitle>
      </Modal.Header>
      <Modal.Content>
        <StyledContentContainer>
          <SelectField
            onSelectChange={(_, option) =>
              setSelectedColumn(
                validColumns.find((c) => c.name === option.value) ?? null
              )
            }
            options={validColumns.map((col) => ({
              label: col.displayName,
              value: col.name,
            }))}
            fullWidth
            label="Colunas"
          />
          {selectedColumn?.type === "ENUM" && (
            <SelectField
              multiple
              onSelectChange={(_, option) => {
                setSelectedOptions(option.map((op: any) => op.value));
              }}
              options={selectedColumn.enumOptions?.map((col) => ({
                label: col,
                value: col,
              }))}
              fullWidth
              label="Colunas"
            />
          )}
          {selectedColumn?.type === "DATE" && (
            <StyledDateContainer>
              <DatePicker
                label="Data de início"
                onDateChange={(value) =>
                  setDateRange((prev) => ({
                    ...prev,
                    startDate: value?.toDate() || null,
                  }))
                }
              />
              <DatePicker
                label="Data limite"
                fromDate={dateRange.startDate ?? undefined}
                onDateChange={(value) =>
                  setDateRange((prev) => ({
                    ...prev,
                    endDate: value?.toDate() || null,
                  }))
                }
              />
            </StyledDateContainer>
          )}
          {selectedColumn?.type === "TEXT" && (
            <StyledDateContainer>
              <TextField
                fullWidth
                label="Buscar"
                onChange={(e) => setTextFilter(e.target.value)}
              />
            </StyledDateContainer>
          )}
        </StyledContentContainer>
      </Modal.Content>
      <Modal.Footer>
        <StyledFooterContainer>
          <LinkButton onClick={closeModal} variant="neutral">
            Cancelar
          </LinkButton>
          <Button
            onClick={() => handleApplyFilter()}
            disabled={disabledButton}
            variant="primary"
            size="medium"
          >
            Aplicar filtro
          </Button>
        </StyledFooterContainer>
      </Modal.Footer>
    </Modal>
  );
};
