import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledBreadcrumbContainer = styled.div`
  margin-top: 40px;
`;

export const StyledNotificationCardContainer = styled.div`
  margin: 20px 0px;
`;

export const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: space-between;
  height: 100%;
`;

export const StyledMainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const StyledMainContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  height: 100%;
  padding-bottom: 24px;
`;

export const StyledTitleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
`;

export const StyledSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledSubTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledEyeIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;
