import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  gap: 24px;
  border-top: solid 1px ${({ theme }) => theme.colors.neutral[90]};
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
