import { Checkbox } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledColumn, StyledColumnLabel } from "./styles";

type ColumnProps = {
  active: boolean;
  label: string;
  onDragStart: () => void;
  onDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  onDrop: () => void;
  onDrag: boolean;
  onCheck: (label: string) => void;
};

export const Column = ({
  active,
  label,
  onDragOver,
  onDragStart,
  onDrop,
  onDrag,
  onCheck,
}: ColumnProps) => {
  const colorOnChecked = active ? "#F20D7A" : "#6b5b66";
  return (
    <StyledColumn
      checked={active}
      dragEventActive={onDrag}
      draggable
      onDragOver={onDragOver}
      onDragStart={onDragStart}
      onDrop={onDrop}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="16"
        viewBox="0 0 10 16"
        fill="none"
      >
        <path
          d="M2.49996 3.00004C2.9602 3.00004 3.33329 2.62694 3.33329 2.16671C3.33329 1.70647 2.9602 1.33337 2.49996 1.33337C2.03972 1.33337 1.66663 1.70647 1.66663 2.16671C1.66663 2.62694 2.03972 3.00004 2.49996 3.00004Z"
          stroke={colorOnChecked}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.49996 8.83337C2.9602 8.83337 3.33329 8.46028 3.33329 8.00004C3.33329 7.5398 2.9602 7.16671 2.49996 7.16671C2.03972 7.16671 1.66663 7.5398 1.66663 8.00004C1.66663 8.46028 2.03972 8.83337 2.49996 8.83337Z"
          stroke={colorOnChecked}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.49996 14.6667C2.9602 14.6667 3.33329 14.2936 3.33329 13.8334C3.33329 13.3731 2.9602 13 2.49996 13C2.03972 13 1.66663 13.3731 1.66663 13.8334C1.66663 14.2936 2.03972 14.6667 2.49996 14.6667Z"
          stroke={colorOnChecked}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.49996 3.00004C7.9602 3.00004 8.33329 2.62694 8.33329 2.16671C8.33329 1.70647 7.9602 1.33337 7.49996 1.33337C7.03972 1.33337 6.66663 1.70647 6.66663 2.16671C6.66663 2.62694 7.03972 3.00004 7.49996 3.00004Z"
          stroke={colorOnChecked}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.49996 8.83337C7.9602 8.83337 8.33329 8.46028 8.33329 8.00004C8.33329 7.5398 7.9602 7.16671 7.49996 7.16671C7.03972 7.16671 6.66663 7.5398 6.66663 8.00004C6.66663 8.46028 7.03972 8.83337 7.49996 8.83337Z"
          stroke={colorOnChecked}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.49996 14.6667C7.9602 14.6667 8.33329 14.2936 8.33329 13.8334C8.33329 13.3731 7.9602 13 7.49996 13C7.03972 13 6.66663 13.3731 6.66663 13.8334C6.66663 14.2936 7.03972 14.6667 7.49996 14.6667Z"
          stroke={colorOnChecked}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <Checkbox onChange={() => onCheck(label)} checked={active} />
      <StyledColumnLabel active={active} variant="body4" weight={700}>
        {label}
      </StyledColumnLabel>
    </StyledColumn>
  );
};
