import {
  DateRangePicker,
  Typography,
  Unstable,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledDrawer = styled(Unstable.Drawer)`
  &.MuiModal-root {
    div {
      &.MuiPaper-root {
        width: 30%;
        padding: 40px 45px 0;
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 22px;
`;

export const StyledDrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const StyledDrawerFilters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 50px;
  max-height: 80vh;
  overflow-y: auto;
`;

export const StyledFilterContainer = styled.div`
  border-radius: 12px;
  border: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const StyledDatePickerContainer = styled.div`
  div > .date-range-picker--inputs {
    width: 100% !important;
    justify-content: space-between !important;
    display: none !important;
  }

  div > .date-range-picker--input-label {
    display: none !important;
  }

  div > .date-range-picker--inputs input {
    width: 100% !important;
  }

  div > .rdp-caption {
    width: 100% !important;
  }

  div > .rdp-caption_end {
    width: 100% !important;
  }

  div > .rdp-table {
    width: 100% !important;
    max-width: 100%;
  }
`;
