import { useCallback } from "react";

type EType = "error" | "success" | "warning" | "info";

interface DispatchToastParams {
  description?: string;
  content: string;
  type: EType;
  title?: string;
}

export function useToast() {
  const showToast = useCallback((detail: DispatchToastParams) => {
    dispatchEvent(new CustomEvent("showReportsToast", { detail }));
  }, []);

  return { showToast };
}
