import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledModalTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[10]};
`;

export const StyledFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
`;

export const StyledDateContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;
