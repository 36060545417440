import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ReportsPage } from "src/pages/ReportsPage";
import { ReportPage } from "src/pages/ReportPage";
import { ModalExport } from "src/shared/components/ModalExport";
import { ExportsDrawer } from "src/shared/components/ExportsDrawer";
import { EditReportProvider } from "src/pages/EditReportPage/context/EditReport.context";
import { EditReportPage } from "src/pages/EditReportPage";
import { ToastRender } from "src/shared/toast";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/reports" element={<ReportsPage />} />
        <Route path="/reports/:id" element={<ReportPage />} />
        <Route
          path="/reports/:id/customize"
          element={
            <EditReportProvider>
              <EditReportPage />
            </EditReportProvider>
          }
        />
      </Routes>
      <ModalExport />
      <ToastRender />
      <ExportsDrawer />
    </BrowserRouter>
  );
};
export default AppRouter;
