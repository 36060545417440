import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledAsideContainer = styled.div`
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  padding: 24px 25px 16px 25px;
  max-width: 290px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
`;

export const StyledAsideText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const StyledFilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledColumnsContainer = styled.div<{ dragActive: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  transition: 0.1s ease-in-out;

  ${({ dragActive }) =>
    dragActive &&
    `     
        border-radius: 8px;
        padding: 5px;
        border: dashed 1px rgb(250, 206, 227);
    `}
`;

export const StyledFilterCard = styled.div`
  border-radius: 8px;
  padding: 12px;
  border: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  display: flex;
  flex-direction: column;
`;

export const StyledFilterCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledFilterCardTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const StyledRemoveLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
`;

export const StyledRemoveLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledRemoveIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
`;
