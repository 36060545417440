import {
  Icons,
  PageContainer,
  Skeleton,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Header } from "./components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "./components/Table";
import { trpc } from "src/api/client";
import { useEffect, useState } from "react";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

import {
  StyledEyeIcon,
  StyledMainContainer,
  StyledMainContent,
  StyledPageContainer,
  StyledSubTitle,
  StyledSubTitleContainer,
  StyledTitle,
  StyledTitleContent,
} from "./styles";
import { Aside } from "./components/Aside";
import { useEditReport } from "../../context/EditReport.context";
import { Footer } from "./components/Footer";
import { useFilter } from "src/hooks/useFilter";

export const CustomizeStep = () => {
  const {
    filter,
    initialColumns,
    setInitialColumns,
    setReportInfo,
    applyFilter,
  } = useEditReport();
  const { parseFilters } = useFilter();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { selectedCompany } = useSelectedCompany();
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const {
    data: report,
    isLoading: isReportLoading,
    error: reportError,
  } = trpc.findReportById.useQuery({
    reportId: id || "",
    companyId: selectedCompany.id,
  });

  useEffect(() => {
    if (report?.columns) {
      setInitialColumns(report?.columns || []);
      setReportInfo({
        title: report.title,
        description: report.description,
        private: report.private,
      });
    }

    if (report?.filter) {
      const parsedFilters = parseFilters(report.filter);
      parsedFilters.forEach((filter) => applyFilter(filter));
    }

    if (!report?.editable) {
      navigate("/reports");
    }
  }, [report]);

  const {
    data: queryResult,
    isLoading: isQueryLoading,
    error: queryError,
  } = trpc.queryReport.useQuery({
    reportId: id || "",
    page: pagination.pageNumber,
    pageSize: pagination.pageSize,
    companyId: selectedCompany.id,
    columns: initialColumns,
    filter,
  });

  if (reportError || queryError) {
    return <p>Error</p>; // Todo - Check error page provided by Design
  }

  return (
    <StyledPageContainer>
      <Header.Component />
      <PageContainer>
        <StyledMainContainer>
          <Aside isLoading={isReportLoading} />
          <StyledMainContent>
            <StyledTitleContent>
              {!isReportLoading ? (
                <StyledTitle variant="headline8">{report?.title}</StyledTitle>
              ) : (
                <Skeleton height={35} width={400} />
              )}
              <StyledSubTitleContainer>
                <StyledEyeIcon name="IconEye" size={20} />
                <StyledSubTitle variant="body4">
                  Exibindo preview do relatório com amostras de 10 itens
                </StyledSubTitle>
              </StyledSubTitleContainer>
            </StyledTitleContent>
            <Table
              headers={initialColumns.filter((col) => col.visible)}
              data={queryResult?.data}
              isLoading={isQueryLoading || isReportLoading}
              pagination={pagination}
              onPagination={setPagination}
              total={queryResult?.total as number}
            />
          </StyledMainContent>
        </StyledMainContainer>
      </PageContainer>
      <Footer.Component />
    </StyledPageContainer>
  );
};
