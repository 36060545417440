import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
`;

export const StyledSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const StyledPillButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
