import { useEffect, useState } from "react";
import {
  StyledButtonContainer,
  StyledDatePickerContainer,
  StyledDrawer,
  StyledDrawerContainer,
  StyledDrawerFilters,
  StyledFilterContainer,
  StyledTitle,
} from "./styles";
import {
  Button,
  DateRangePicker,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";

type DrawerFilterProps = {
  open: boolean;
  filter: any;
  onClose: () => void;
  onSubmit: (data: any) => void;
};

export const DrawerFilter = ({
  open,
  onClose,
  filter,
  onSubmit,
}: DrawerFilterProps) => {
  const [dateRange, setDateRange] = useState<{
    from?: Date | null;
    to?: Date | null;
  }>({ from: null, to: null });

  useEffect(() => {
    if (filter.dateRange) {
      setDateRange(filter.dateRange);
    }
  }, [filter]);

  return (
    <StyledDrawer title="Filtros" open={open} onClose={onClose}>
      <StyledDrawerContainer>
        <StyledDrawerFilters>
          <StyledFilterContainer>
            <StyledTitle variant="headline9">Data de criação</StyledTitle>
            <StyledDatePickerContainer>
              <DateRangePicker
                disableFooter
                onSelect={(r: any) => setDateRange(r)}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                selected={dateRange}
                onSubmit={() => null}
              />
            </StyledDatePickerContainer>
          </StyledFilterContainer>
        </StyledDrawerFilters>

        <StyledButtonContainer>
          <LinkButton
            onClick={() => {
              onSubmit({});
              setDateRange({});
            }}
            variant="neutral"
          >
            Limpar filtros
          </LinkButton>
          <Button
            onClick={() => {
              onSubmit({ dateRange });
              onClose();
            }}
            variant="primary"
            variantType="default"
          >
            Filtrar
          </Button>
        </StyledButtonContainer>
      </StyledDrawerContainer>
    </StyledDrawer>
  );
};
