import { dayjs, PillButton, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledAsideContainer,
  StyledAsideText,
  StyledColumnsContainer,
  StyledFilterCard,
  StyledFilterCardHeader,
  StyledFilterCardTitle,
  StyledFilterContainer,
  StyledOptionsContainer,
  StyledRemoveIcon,
  StyledRemoveLabel,
  StyledRemoveLabelContainer,
} from "./styled";
import { useEffect, useState } from "react";
import { ModalFilter } from "../ModalFilter";
import { Column as ColumnType } from "src/shared/types";
import { Column } from "../Column";
import { useDrag } from "src/hooks/useDrag";
import { useEditReport } from "../../../../context/EditReport.context";

type AsideProps = {
  isLoading: boolean;
};

export const Aside = ({ isLoading }: AsideProps) => {
  const {
    filter,
    removeFilter,
    removeOption,
    initialColumns,
    setInitialColumns,
  } = useEditReport();
  const drag = useDrag(initialColumns, setInitialColumns);
  const [openModal, setOpenModal] = useState(false);

  const toggleItem = (label: string) => {
    setInitialColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.displayName === label ? { ...col, visible: !col.visible } : col
      )
    );
  };

  return (
    <StyledAsideContainer>
      <StyledAsideText variant="headline8">Opções de edição</StyledAsideText>
      <StyledFilterContainer>
        <StyledAsideText variant="headline9">Filtros</StyledAsideText>
        <PillButton
          disabled={isLoading}
          onClick={() => setOpenModal(true)}
          type="primary"
          variant="pink"
          size="extra-small"
          iconPosition="right"
          icon="IconPlus"
          label="Adicionar filtros"
        />
      </StyledFilterContainer>
      {filter?.map((f) => (
        <StyledFilterCard>
          <StyledFilterCardHeader>
            <StyledFilterCardTitle weight={600} variant="body4">
              {f.displayName}
            </StyledFilterCardTitle>
            <StyledRemoveLabelContainer>
              <StyledRemoveLabel
                onClick={() => removeFilter(f.column)}
                variant="caption"
                weight={700}
              >
                Remover
              </StyledRemoveLabel>
              <StyledRemoveIcon name="IconX" size={16} />
            </StyledRemoveLabelContainer>
          </StyledFilterCardHeader>
          {f.type === "ENUM" && (
            <StyledOptionsContainer>
              {f.options.map((option) => (
                <PillButton
                  onClick={() => removeOption(f.column, option)}
                  key={option}
                  type="primary"
                  variant="default"
                  size="extra-small"
                  iconPosition="right"
                  icon="IconX"
                  label={option}
                />
              ))}
            </StyledOptionsContainer>
          )}

          {f.type === "DATE" && (
            <StyledOptionsContainer>
              <PillButton
                key={f.column}
                type="primary"
                variant="default"
                size="extra-small"
                label={`${dayjs(f.dateRange.startDate).format(
                  "DD/MM/YYYY"
                )} - ${dayjs(f.dateRange.endDate).format("DD/MM/YYYY")}`}
              />
            </StyledOptionsContainer>
          )}

          {f.type === "TEXT" && (
            <StyledOptionsContainer>
              <PillButton
                key={f.column}
                type="primary"
                variant="default"
                size="extra-small"
                label={f.value}
              />
            </StyledOptionsContainer>
          )}
        </StyledFilterCard>
      ))}
      <StyledAsideText variant="headline9">
        Personalizar colunas
      </StyledAsideText>
      <StyledColumnsContainer dragActive={drag.draggingIndex !== null}>
        {initialColumns.map((column, index) => (
          <Column
            onCheck={(label) => toggleItem(label)}
            onDrag={drag.draggingIndex === index}
            onDragOver={(e) => drag.handleDragOver(e, index)}
            onDragStart={() => drag.handleDragStart(index)}
            onDrop={() => drag.handleDrop()}
            key={column.name}
            active={column.visible}
            label={column?.displayName || column.name}
          />
        ))}
        {isLoading &&
          Array.from({ length: 5 }).map((_, index) => (
            <Skeleton key={index} width="100%" height={45} />
          ))}
      </StyledColumnsContainer>
      <ModalFilter
        columns={initialColumns}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </StyledAsideContainer>
  );
};
