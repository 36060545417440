import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledDateFilterContainer = styled.div`
  border-radius: 12px;
  border: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const StyledOption = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

export const StyledOptionContainer = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.secondary[99] : "inherit"};
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
`;
