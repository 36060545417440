import { CustomizeStep } from "./components/CustomizeStep";
import { FormStep } from "./components/FormStep";
import { SuccessStep } from "./components/SuccessStep";
import { Steps, useEditReport } from "./context/EditReport.context";

const StepList = {
  [Steps.CUSTOMIZE]: CustomizeStep,
  [Steps.FORM]: FormStep,
  [Steps.SUCCESS]: SuccessStep,
};

export const EditReportPage = () => {
  const { currentStep } = useEditReport();
  const Component = StepList[currentStep];

  return <Component />;
};
