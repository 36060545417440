import { Unstable } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledDrawer = styled(Unstable.Drawer)`
  &.MuiModal-root {
    div {
      &.MuiPaper-root {
        width: 30%;
        padding: 40px 45px 0;
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 22px;
`;

export const StyledDrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const StyledDrawerFilters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 50px;
  max-height: 80vh;
  overflow-y: auto;
`;
