import { TextField } from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledOptionsContainer,
  StyledTextFilterContainer,
  StyledTitle,
} from "./styles";
import { Filter } from "src/shared/types";

type TextFilterProps = {
  title: string;
  column: string;
  value: string;
  onChange: <T extends Filter>(filterData: T) => void;
};

export const TextFilter = ({
  title,
  value,
  onChange,
  column,
}: TextFilterProps) => {
  return (
    <StyledTextFilterContainer>
      <StyledTitle variant="headline9" weight={700}>
        {title}
      </StyledTitle>
      <StyledOptionsContainer>
        <TextField
          fullWidth
          label="Buscar"
          value={value}
          onChange={(e) => {
            onChange({
              type: "TEXT",
              column: column,
              displayName: title,
              value: e.target.value,
            });
          }}
        />
      </StyledOptionsContainer>
    </StyledTextFilterContainer>
  );
};
