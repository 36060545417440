import {
  PillButton,
  Table as SoftTable,
  tableControllers,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { Dispatch, SetStateAction, useDeferredValue, useState } from "react";
import { trpc } from "src/api/client";
import {
  StyledActions,
  StyledActionsContainer,
  StyledDownloadIcon,
  StyledIcon,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { useCopyReport } from "src/hooks/useCopyReport";
import { DrawerFilter } from "../DrawerFilter";
import { useDebouncedValue } from "src/hooks/useDebouncedValue";

type TableProps = {
  moduleId: string | null;
  openModal: (id: string, title: string) => void;
};

export const Table = ({ moduleId, openModal }: TableProps) => {
  const { selectedCompany } = useSelectedCompany();
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [query, setQuery] = useState("");
  const queryValue = useDebouncedValue(query, 1000);
  const [filter, setFilter] = useState<{
    dateRange: { from?: Date; to?: Date };
  }>({ dateRange: { from: undefined, to: undefined } });
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const { data, isLoading } = trpc.findReports.useQuery({
    companyId: selectedCompany.id,
    moduleId,
    pageSize: pagination.pageSize,
    page: pagination.pageNumber,
    dateRange: {
      from: filter?.dateRange?.from?.toISOString(),
      to: filter?.dateRange?.to?.toISOString(),
    },
    query: queryValue,
  });

  const { table, total } = useTableData(
    data?.reports,
    openModal,
    data?.total ?? 0,
    pagination,
    setPagination
  );

  const pageSizeOptions = [
    {
      label: "5 itens",
      value: 5,
    },
    {
      label: "10 itens",
      value: 10,
    },
    {
      label: "25 itens",
      value: 25,
    },
  ];

  return (
    <>
      <SoftTable.Root variant="soft">
        <SoftTable.Content>
          <SoftTable.FilterSearch onSearch={(e) => setQuery(e.target.value)}>
            <div data-onboarding="reports-filter">
              <PillButton
                icon="IconFilter"
                label="Filtros"
                onClick={() => setOpenFilterDrawer(true)}
                variant="default"
                size="small"
                type="primary"
              />
            </div>
          </SoftTable.FilterSearch>

          <SoftTable.Grid.Root loading={isLoading}>
            <SoftTable.Grid.Header
              getHeaderGroups={table.getHeaderGroups}
              toggleAllRowsExpanded={table.toggleAllRowsExpanded}
            />

            {table.rows.map((row, index) => (
              <SoftTable.Grid.Row key={index + row.id} row={row} />
            ))}
          </SoftTable.Grid.Root>

          <SoftTable.Pagination
            count={total}
            onPaginationChange={({ pageSize, pageNumber }) =>
              setPagination({ ...pagination, pageSize, pageNumber })
            }
            showItemRange
            pagination={pagination}
            pageSizeOptions={pageSizeOptions}
          />
        </SoftTable.Content>
      </SoftTable.Root>
      <DrawerFilter
        open={openFilterDrawer}
        filter={filter}
        onClose={() => setOpenFilterDrawer(false)}
        onSubmit={(data) => {
          setFilter(data);
        }}
      />
    </>
  );
};

function useTableData(
  reports: any,
  openModal: (id: string, title: string) => void,
  total: number,
  pagination: {
    pageNumber: number;
    pageSize: number;
  },
  setPagination: Dispatch<
    SetStateAction<{
      pageNumber: number;
      pageSize: number;
    }>
  >
) {
  const navigate = useNavigate();
  const { copyReport } = useCopyReport();

  const data = reports || [];

  const columns = [
    {
      header: "Relatório",
      accessorKey: "title",
      minSize: 200,
    },
    {
      header: "Data de criação",
      accessorKey: "createdAt",
    },
    {
      header: "Tipo",
      accessorKey: "type",
    },
    {
      header: "Criador",
      accessorKey: "owner",
    },
    {
      header: "Ações",
      minSize: 100,
      sticky: "right",
      accessorKey: "actions",
      cell: ({ row }: any) => (
        <StyledActionsContainer>
          <StyledActions>
            <Tooltip title="Exportar relatório">
              <div data-onboarding="export-report">
                <StyledDownloadIcon
                  onClick={() => {
                    openModal(row?.original?.id, row?.original?.title);
                  }}
                  name="IconDownload"
                  size={20}
                />
              </div>
            </Tooltip>
            <Tooltip title="Visualizar relatório">
              <div data-onboarding="view-report">
                <StyledIcon
                  onClick={() => navigate(`/reports/${row?.original?.id}`)}
                  name="IconFileDescription"
                  size={20}
                />
              </div>
            </Tooltip>

            <Tooltip title="Duplicar relatório">
              <div data-onboarding="duplicate-report">
                <StyledIcon
                  onClick={() => copyReport(row?.original?.id)}
                  name="IconBoxMultiple"
                  size={20}
                />
              </div>
            </Tooltip>
          </StyledActions>
        </StyledActionsContainer>
      ),
    },
  ];

  const hasModule = data.some((item: any) => item?.module);

  if (hasModule) {
    columns.splice(2, 0, {
      header: "Módulo",
      accessorKey: "module",
      minSize: 100,
      sticky: "none",
      cell: ({ row }: any) => <>{row?.original?.module?.title}</>,
    });
  }

  const table = tableControllers.useTableColumns<any>({
    defaultColumn: {
      minSize: 100,
    },
    total: total,
    columns: columns as any,
    data,
    pagination: pagination,
    onPaginationChange: setPagination,
  });

  return {
    table,
    pagination,
    total,
    setPagination,
  };
}
