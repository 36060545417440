import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledColumn = styled.div<{
  dragEventActive: boolean;
  checked: boolean;
}>`
  display: flex;
  width: 100%;
  height: 44px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  padding: 12px;
  background-color: ${({ theme, checked }) =>
    checked ? theme.colors.secondary[99] : "none"};
  border: ${({ theme, checked }) =>
    !checked ? `solid 1px ${theme.colors.neutral[90]}` : "none"};
  transition: transform 0.5s ease-in-out;
  cursor: grabbing;

  ${({ dragEventActive }) =>
    dragEventActive &&
    ` 
      cursor: grabbing !important;
      transform: scale(1.1);
    `}
`;

export const StyledColumnLabel = styled(Typography)<{ active: boolean }>`
  color: ${({ theme, active }) =>
    active ? theme.colors.brand[50] : theme.colors.neutral[40]};
`;
