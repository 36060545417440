import { Modal } from "src/components/Modal";
import {
  StyledContainer,
  StyledContainerSection,
  StyledFooterContainer,
  StyledInput,
  StyledModalSubTitle,
  StyledModalTitle,
  StyledOptionContainer,
  StyledSectionSubTitle,
  StyledSectionTitle,
} from "./styles";
import { Option } from "./components/Option";
import {
  Button,
  IconsProps,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect, useState } from "react";
import { trpc } from "src/api/client";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { useExportsContext } from "src/shared/context/export.context";

const options: {
  fileType: string;
  icon: IconsProps["name"];
  enabled: boolean;
}[] = [
  {
    fileType: "XLSX",
    icon: "IconFileTypeXls",
    enabled: true,
  },
  {
    fileType: "PDF",
    icon: "IconFileTypePdf",
    enabled: true,
  },
  {
    fileType: "CSV",
    icon: "IconFileTypeCsv",
    enabled: true,
  },
  {
    fileType: "TXT",
    icon: "IconFileTypeTxt",
    enabled: true,
  },
];

export const ModalExport = () => {
  const { exportModal, onCloseModal, onOpenDrawer } = useExportsContext();
  const { selectedCompany } = useSelectedCompany();
  const [selectedOption, setSelectedOption] = useState("XLSX");
  const [fileName, setFileName] = useState("");
  const utils = trpc.useContext();
  const { mutateAsync: exportReport, isLoading } =
    trpc.exportReport.useMutation({
      onSuccess: () => {
        utils.findExports.invalidate();
        onCloseModal();
        onOpenDrawer();
      },
    });

  useEffect(() => {
    if (exportModal?.reportTitle) {
      setFileName(exportModal?.reportTitle);
    }
  }, [exportModal.reportTitle]);

  return (
    <Modal isOpen={exportModal.opened} onClose={onCloseModal} size="sm">
      <Modal.Header>
        <StyledModalTitle variant="headline8">
          Exportar relatório
        </StyledModalTitle>
        <StyledModalSubTitle variant="body4">
          Insira o nome do arquivo a ser exportado, junto com sua extensão ou
          formato, e faça o download. Apenas os dados filtrados serão incluídos
          na exportação.
        </StyledModalSubTitle>
      </Modal.Header>
      <Modal.Content>
        <StyledContainer>
          <StyledContainerSection>
            <StyledSectionTitle variant="body3">
              Defina um nome para o arquivo
            </StyledSectionTitle>
            <StyledInput
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              label="Nome do arquivo"
            />
          </StyledContainerSection>

          <StyledContainerSection>
            <StyledSectionTitle variant="body3">
              Selecione o formato do arquivo
            </StyledSectionTitle>
            <StyledSectionSubTitle variant="body4">
              Escolha a extensão do arquivo do relatório gerado.
            </StyledSectionSubTitle>
            <StyledOptionContainer>
              {options.map((option) => (
                <Option
                  checked={option.fileType === selectedOption}
                  onCheck={(option) => setSelectedOption(option)}
                  icon={option.icon}
                  text={option.fileType}
                  enabled={option.enabled}
                />
              ))}
            </StyledOptionContainer>
          </StyledContainerSection>
        </StyledContainer>
      </Modal.Content>
      <Modal.Footer>
        <StyledFooterContainer>
          <LinkButton onClick={onCloseModal} variant="neutral">
            Cancelar
          </LinkButton>
          <Button
            onClick={() =>
              exportReport({
                companyId: selectedCompany.id,
                type: selectedOption,
                fileName,
                reportId: exportModal.reportId,
              })
            }
            loading={isLoading}
            disabled={!fileName || isLoading}
            variant="primary"
            size="medium"
          >
            Exportar relatório
          </Button>
        </StyledFooterContainer>
      </Modal.Footer>
    </Modal>
  );
};
