import { PageContainer, PillButton } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledPageContainer } from "../CustomizeStep/styles";
import { Header } from "../CustomizeStep/components/Header";
import { Footer } from "./components/Footer";
import {
  StyledMain,
  StyledPillButtonContainer,
  StyledSubTitle,
  StyledTitle,
} from "./styles";

export const SuccessStep = () => {
  return (
    <StyledPageContainer>
      <Header.Component />
      <PageContainer>
        <StyledMain>
          <StyledPillButtonContainer>
            <PillButton
              size="medium"
              variant="success"
              icon="IconCircleCheck"
              type="primary"
            />
          </StyledPillButtonContainer>

          <StyledTitle variant="headline8" data-onboarding="edit-success">
            Relatório salvo com sucesso!
          </StyledTitle>
          <StyledSubTitle variant="body4">
            Acesse os detalhe para mais informações.
          </StyledSubTitle>
        </StyledMain>
      </PageContainer>
      <Footer.Component />
    </StyledPageContainer>
  );
};
