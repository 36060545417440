import { trpc } from "src/api/client";
import { ExportCard } from "../ExportCard";
import { StyledContainer, StyledDrawer } from "./styles";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { EmptyState } from "./empty-state";
import { useExportsContext } from "src/shared/context/export.context";

export const ExportsDrawer = () => {
  const { openDrawer, onCloseDrawer } = useExportsContext();
  const { selectedCompany } = useSelectedCompany();
  const { data, isLoading } = trpc.findExports.useQuery(
    {
      companyId: selectedCompany.id,
    },
    { refetchOnWindowFocus: true }
  );

  return (
    <StyledDrawer
      title="Central de downloads"
      subtitle="Após 24 horas os arquivos expirarão, mas você pode solicitar a exportação novamente."
      open={openDrawer}
      onClose={onCloseDrawer}
    >
      <StyledContainer>
        {data?.length === 0 && <EmptyState />}
        {isLoading &&
          new Array(5)
            .fill(null)
            .map((_, index) => (
              <Skeleton
                key={index}
                width="100%"
                variant="rounded"
                animation="wave"
                height={64.3}
              />
            ))}
        {data &&
          data?.map((exp) => (
            <ExportCard
              exportId={exp.id}
              type={exp.status}
              title={exp.fileName}
              date={exp.createdAt}
              companyId={selectedCompany.id}
            />
          ))}
      </StyledContainer>
    </StyledDrawer>
  );
};
