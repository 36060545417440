import {
  StyledButtonContainer,
  StyledDrawer,
  StyledDrawerContainer,
  StyledDrawerFilters,
} from "./styles";
import { Column, Filter } from "../../../../shared/types";
import { DateFilter } from "../DateFilter";
import { useEffect, useState } from "react";
import { EnumFilter } from "../EnumFilter";
import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { TextFilter } from "../TextFilter";

type DrawerFilterProps = {
  open: boolean;
  onClose: () => void;
  filter: Filter[];
  onChange: <T extends Filter>(filterData: T) => void;
  columns: Column[];
};

export const DrawerFilter = ({
  open,
  onClose,
  filter,
  onChange,
  columns,
}: DrawerFilterProps) => {
  const [localFilter, setLocalFilter] = useState<Filter[]>([]);

  useEffect(() => {
    if (filter) {
      setLocalFilter(filter);
    }
  }, [filter]);

  const onFilter = <T extends Filter>(filterData: T) => {
    setLocalFilter((prevFilters) => {
      const existingFilterIndex = prevFilters.findIndex(
        (f) => f.column === filterData.column
      );

      if (existingFilterIndex >= 0) {
        const updatedFilters = [...prevFilters];
        updatedFilters[existingFilterIndex] = filterData;
        return updatedFilters;
      } else {
        return [...prevFilters, filterData];
      }
    });
  };

  return (
    <StyledDrawer title="Filtros" open={open} onClose={onClose}>
      <StyledDrawerContainer>
        <StyledDrawerFilters>
          {columns.map((c) => {
            const prevFilter = localFilter.find((f) => f.column === c.name);
            if (c.type === "DATE") {
              const dataRange =
                prevFilter?.type === "DATE"
                  ? {
                      ...prevFilter.dateRange,
                    }
                  : {
                      startDate: null,
                      endDate: null,
                    };
              return (
                <DateFilter
                  column={c.name}
                  key={c.displayName}
                  title={c.displayName || c.name}
                  date={dataRange}
                  onChange={onFilter}
                />
              );
            }

            if (c.type === "ENUM") {
              return (
                <EnumFilter
                  column={c.name}
                  key={c.displayName}
                  title={c.displayName || c.name}
                  value={prevFilter?.type === "ENUM" ? prevFilter.options : []}
                  options={c.enumOptions || []}
                  onChange={onFilter}
                />
              );
            }

            if (c.type === "TEXT") {
              return (
                <TextFilter
                  column={c.name}
                  key={c.displayName}
                  title={c.displayName || c.name}
                  value={prevFilter?.type === "TEXT" ? prevFilter.value : ""}
                  onChange={onFilter}
                />
              );
            }
            return null;
          })}
        </StyledDrawerFilters>

        <StyledButtonContainer>
          <LinkButton onClick={() => onClose()} variant="neutral">
            Cancelar
          </LinkButton>
          <Button
            onClick={() => {
              localFilter.forEach((f) => onChange(f));
              onClose();
            }}
            variant="primary"
            variantType="default"
          >
            Filtrar
          </Button>
        </StyledButtonContainer>
      </StyledDrawerContainer>
    </StyledDrawer>
  );
};
