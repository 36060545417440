import { useState } from "react";
import { Filter } from "src/shared/types";

export const useFilter = () => {
  const [filter, setFilter] = useState<Filter[]>([]);

  const applyFilter = <T extends Filter>(filterData: T) => {
    setFilter((prevFilters) => {
      const existingFilterIndex = prevFilters.findIndex(
        (f) => f.column === filterData.column
      );

      if (existingFilterIndex >= 0) {
        const updatedFilters = [...prevFilters];
        updatedFilters[existingFilterIndex] = filterData;
        return updatedFilters;
      } else {
        return [...prevFilters, filterData];
      }
    });
  };

  const parseFilters = (filters: any[]): Filter[] => {
    return filters.map((filter) => {
      if (filter.type === "DATE" && filter.dateRange) {
        return {
          ...filter,
          dateRange: {
            startDate: filter.dateRange.startDate
              ? new Date(filter.dateRange.startDate)
              : null,
            endDate: filter.dateRange.endDate
              ? new Date(filter.dateRange.endDate)
              : null,
          },
        };
      }
      return filter;
    });
  };

  const removeFilter = (column: string) => {
    setFilter((prevFilters) => prevFilters.filter((f) => f.column !== column));
  };

  const removeOption = (column: string, option: string) => {
    setFilter((prevFilters) =>
      prevFilters.map((f) => {
        if (f.type === "ENUM" && f.column === column) {
          return { ...f, options: f.options.filter((opt) => opt !== option) };
        }
        return f;
      })
    );
  };

  return { filter, applyFilter, removeFilter, removeOption, parseFilters };
};
