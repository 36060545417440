import { LinkButton, PillButton } from "@flash-tecnologia/hros-web-ui-v2";
import {
  HeaderContainer,
  StyledBackButton,
  StyledBackButtonText,
  StyledLeftArrowIcon,
  StyledTitle,
} from "./styles";
import { useNavigate } from "react-router-dom";

const Component = () => {
  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <StyledBackButton>
        <StyledLeftArrowIcon name="IconChevronLeft" size={20} />
        <StyledBackButtonText
          onClick={() => navigate("/reports")}
          variant="body4"
          weight={700}
        >
          Voltar para Relatório de dados
        </StyledBackButtonText>
      </StyledBackButton>
      <StyledTitle variant="headline9">Editar relatório</StyledTitle>
      <PillButton
        size="extra-small"
        variant="default"
        type="secondary"
        icon="IconHelp"
      />
    </HeaderContainer>
  );
};

const Header = {
  Component,
};

export { Header };
