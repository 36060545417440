import {
  Button,
  Icons,
  Tag,
  Tooltip,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  HeaderContainer,
  HeaderText,
  StyledButtonContainer,
  StyledTitleContainer,
  Subtitle,
} from "./styles";

type HeaderProps = {
  openDrawer: () => void;
};

export const Header = ({ openDrawer }: HeaderProps) => {
  return (
    <HeaderContainer>
      <HeaderText>
        <StyledTitleContainer>
          <Typography variant="headline6">Central de Relatórios</Typography>
          <Tag variant="pink" size="extra-small">
            Beta
          </Tag>
        </StyledTitleContainer>
        <Subtitle>
          Explore relatórios prontos ou personalize o seu próprio a partir de
          diversos modelos disponíveis.
        </Subtitle>
      </HeaderText>
      <StyledButtonContainer>
        <Tooltip title="Sempre que exportar um relatório, você poderá consultar seu processamento e baixá-lo por aqui.">
          <Button
            data-onboarding="exports-drawer"
            onClick={() => openDrawer()}
            variant="secondary"
            size="medium"
            variantType="neutral"
          >
            Central de downloads
            <Icons name="IconDownload" />
          </Button>
        </Tooltip>
      </StyledButtonContainer>
    </HeaderContainer>
  );
};
