import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  justify-self: start;
`;

export const StyledMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
`;

export const StyledRadioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

export const StyledRadioOption = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  border: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

export const StyledOptionText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const StyledOptionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const StyledOptionSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;
