import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  margin: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderText = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  max-width: 60%;
`;

export const Subtitle = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledButtonContainer = styled(Button)`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
