import { Spinner } from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledCard,
  StyledFooter,
  StyledProcessingLabel,
  StyledCompletedLabel,
  StyledTitle,
  StyledDownloadIcon,
  StyledTitleContainer,
  StyledDate,
} from "./styled";
import { trpc } from "src/api/client";

type ExportCardProps = {
  type: "PROCESSING" | "COMPLETED";
  title: string;
  exportId: string;
  companyId: string;
  date: string;
};

const ProcessingState = () => {
  return (
    <StyledFooter>
      <StyledProcessingLabel variant="caption">
        Em processamento
      </StyledProcessingLabel>
      <Spinner variant="primary" size={16} />
    </StyledFooter>
  );
};

const CompletedState: React.FC<{
  exportId: string;
  companyId: string;
  fileName: string;
}> = ({ exportId, companyId }) => {
  const { mutateAsync } = trpc.downloadFile.useMutation();
  const utils = trpc.useContext();

  const downloadFile = async () => {
    const url = await mutateAsync({ companyId, exportId });
    window.open(url, "_blank");
    await utils.findExports.invalidate({ companyId });
  };

  return (
    <StyledFooter onClick={downloadFile} cursor="pointer">
      <StyledCompletedLabel variant="caption" weight={700}>
        Exportar relatório
      </StyledCompletedLabel>
      <StyledDownloadIcon name="IconDownload" size={16} />
    </StyledFooter>
  );
};

export const ExportCard = ({
  type,
  title,
  exportId,
  companyId,
  date,
}: ExportCardProps) => {
  return (
    <StyledCard>
      <StyledTitleContainer>
        <StyledTitle title={title} variant="body4" weight={600}>
          {title}
        </StyledTitle>
        <StyledDate title={date} variant="caption" weight={400}>
          {date}
        </StyledDate>
      </StyledTitleContainer>

      {type === "PROCESSING" && <ProcessingState />}
      {type === "COMPLETED" && (
        <CompletedState
          exportId={exportId}
          companyId={companyId}
          fileName={title}
        />
      )}
    </StyledCard>
  );
};
