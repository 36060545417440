import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledTextFilterContainer = styled.div`
  border-radius: 12px;
  border: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const StyledOption = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;
