import {
  DatePicker,
  dayjs,
  Radio,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledDateFilterContainer,
  StyledDatePickerContainer,
  StyledOption,
  StyledOptionsContainer,
  StyledTextContainer,
  StyledTitle,
} from "./styles";
import { Filter } from "src/shared/types";
import { useState } from "react";

type DateFilterProps = {
  title: string;
  column: string;
  date: {
    startDate: Date | null;
    endDate: Date | null;
  };
  onChange: <T extends Filter>(filterData: T) => void;
};

const subtractDays = (days: number): string =>
  new Date(new Date().setDate(new Date().getDate() - days)).toISOString();

const defaultOptions = [
  {
    title: "Últimos 30 dias",
    startDate: subtractDays(30),
    endDate: new Date().toISOString(),
  },
  {
    title: "Últimos 15 dias",
    startDate: subtractDays(15),
    endDate: new Date().toISOString(),
  },
  {
    title: "Últimos 7 dias",
    startDate: subtractDays(7),
    endDate: new Date().toISOString(),
  },
];

export const DateFilter = ({
  title,
  date,
  onChange,
  column,
}: DateFilterProps) => {
  const [selectDefaltOption, setSelectDefaultOption] = useState("");

  return (
    <StyledDateFilterContainer>
      <StyledTitle variant="headline9" weight={700}>
        {title}
      </StyledTitle>
      <StyledOptionsContainer>
        {defaultOptions.map((option) => (
          <StyledOption
            onClick={() => {
              setSelectDefaultOption(option.title);
              onChange({
                type: "DATE",
                column: column,
                displayName: title,
                dateRange: {
                  startDate: new Date(option.startDate),
                  endDate: new Date(option.endDate),
                },
              });
            }}
          >
            <Radio checked={selectDefaltOption === option.title} />
            <StyledTextContainer>
              <Typography weight={600} variant="body4">
                {option.title}
              </Typography>
              <Typography variant="caption">
                {dayjs(option.startDate).format("DD/MM/YYYY")} -{" "}
                {dayjs(option.endDate).format("DD/MM/YYYY")}
              </Typography>
            </StyledTextContainer>
          </StyledOption>
        ))}
        <StyledDatePickerContainer>
          <DatePicker
            disabled={!!selectDefaltOption}
            label="Data de início"
            value={dayjs(date.startDate)}
            onDateChange={(value) => {
              onChange({
                type: "DATE",
                column: column,
                displayName: title,
                dateRange: {
                  startDate: value?.toDate() || null,
                  endDate: date.endDate,
                },
              });
            }}
          />
          <DatePicker
            disabled={!!selectDefaltOption}
            label="Data limite"
            fromDate={dayjs(date.startDate)}
            value={dayjs(date.endDate)}
            onDateChange={(value) => {
              onChange({
                type: "DATE",
                column: column,
                displayName: title,
                dateRange: {
                  startDate: date.startDate,
                  endDate: value?.toDate() || null,
                },
              });
            }}
          />
        </StyledDatePickerContainer>
      </StyledOptionsContainer>
    </StyledDateFilterContainer>
  );
};
