import { Tab, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledTabs = styled(Tab)`
  width: 100%;
`;

export const StyledModuleItem = styled.li<{ active?: boolean }>`
  width: 100%;
  border: solid 1px
    ${({ theme, active }) =>
      active ? theme.colors.brand[50] : theme.colors.neutral[90]};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.secondary[95] : "inherit"};
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
`;

export const StyledModuleList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;
  min-width: 216px;
`;

export const StyledModuleItemLabel = styled(Typography)<{ active?: boolean }>`
  color: ${({ theme, active }) =>
    active ? theme.colors.brand[50] : theme.colors.neutral[50]};
  font-weight: 600;
  transition: color 0.3s ease;
`;

export const StyledTableContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 24px;
`;
