import React, { createContext, useContext, ReactNode, useState } from "react";
import { useFilter } from "src/hooks/useFilter";
import { Column, Filter } from "src/shared/types";

interface EditReportContextType {
  filter?: Filter[];
  applyFilter: <T extends Filter>(filterData: T) => void;
  removeFilter: (column: string) => void;
  removeOption: (column: string, option: string) => void;
  currentStep: Steps;
  setCurrentStep: (step: Steps) => void;
  initialColumns: Column[];
  setInitialColumns: React.Dispatch<React.SetStateAction<Column[]>>;
  reportInfo: {
    title: string;
    description: string;
    private: boolean;
  };
  setReportInfo: React.Dispatch<
    React.SetStateAction<{
      title: string;
      description: string;
      private: boolean;
    }>
  >;
}

const EditReportContext = createContext<EditReportContextType | undefined>(
  undefined
);

interface EditReportProviderProps {
  children: ReactNode;
}

export enum Steps {
  "CUSTOMIZE" = "CUSTOMIZE",
  "FORM" = "FORM",
  "SUCCESS" = "SUCCESS",
}

export const EditReportProvider: React.FC<EditReportProviderProps> = ({
  children,
}) => {
  const { applyFilter, filter, removeFilter, removeOption } = useFilter();
  const [currentStep, setCurrentStep] = useState(Steps.CUSTOMIZE);
  const [initialColumns, setInitialColumns] = useState<Column[]>([]);
  const [reportInfo, setReportInfo] = useState({
    title: "",
    description: "",
    private: false,
  });

  return (
    <EditReportContext.Provider
      value={{
        applyFilter,
        filter,
        removeFilter,
        removeOption,
        currentStep,
        setCurrentStep,
        initialColumns,
        setInitialColumns,
        reportInfo,
        setReportInfo,
      }}
    >
      {children}
    </EditReportContext.Provider>
  );
};

export const useEditReport = (): EditReportContextType => {
  const context = useContext(EditReportContext);
  if (!context) {
    throw new Error("useEditReport must be used within a EditReportProvider");
  }
  return context;
};
