import {
  PageContainer,
  Radio,
  TextArea,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2";
import { StyledPageContainer } from "../CustomizeStep/styles";
import { Header } from "../CustomizeStep/components/Header";
import { Footer } from "./components/Footer";
import {
  StyledMain,
  StyledOptionSubTitle,
  StyledOptionText,
  StyledOptionTitle,
  StyledRadioContainer,
  StyledRadioOption,
  StyledTitle,
} from "./styles";
import { useEditReport } from "../../context/EditReport.context";

const options = [
  {
    title: "Público",
    description:
      "O relatório será adicionado à biblioteca de modelos prontos e estará disponível para todos que tiverem acesso a área.",
    value: false,
  },
  {
    title: "Privado",
    description: "Só você poderá ver e acessar este relatório.",
    value: true,
  },
];

export const FormStep = () => {
  const { reportInfo, setReportInfo } = useEditReport();
  return (
    <StyledPageContainer>
      <Header.Component />
      <PageContainer>
        <StyledMain>
          <StyledTitle variant="headline8">
            Nome e descrição do relatório
          </StyledTitle>
          <TextField
            value={reportInfo.title}
            label="Nome do relatório"
            onChange={(e) =>
              setReportInfo((prev) => ({ ...prev, title: e.target.value }))
            }
          />
          <TextArea
            maxLength={100}
            height={200}
            value={reportInfo.description}
            placeholder="Descrição (opcional)"
            onChange={(e: any) =>
              setReportInfo((prev) => ({
                ...prev,
                description: e.target.value,
              }))
            }
          />
          <StyledTitle variant="headline8">Compartilhamento</StyledTitle>
          <StyledRadioContainer>
            {options.map((opt) => (
              <StyledRadioOption
                onClick={() =>
                  setReportInfo((prev) => ({
                    ...prev,
                    private: opt.value,
                  }))
                }
              >
                <Radio checked={reportInfo.private === opt.value} />
                <StyledOptionText>
                  <StyledOptionTitle weight={700} variant="body4">
                    {opt.title}
                  </StyledOptionTitle>
                  <StyledOptionSubTitle variant="caption">
                    {opt.description}
                  </StyledOptionSubTitle>
                </StyledOptionText>
              </StyledRadioOption>
            ))}
          </StyledRadioContainer>
        </StyledMain>
      </PageContainer>
      <Footer.Component />
    </StyledPageContainer>
  );
};
